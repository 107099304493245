document.addEventListener('DOMContentLoaded', () => {
    let scrollUp = document.getElementById("scroll-up");

    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
        if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
            scrollUp.style.right = "24px";
            scrollUp.style.opacity = "1";
            scrollUp.style.visibility = "visible";
        } else {
            scrollUp.style.right = "0";
            scrollUp.style.opacity = "0";
            scrollUp.style.visibility = "hidden";
        }
    }

    function topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    if (scrollUp) {
        scrollUp.addEventListener('click', topFunction);
    }
});
