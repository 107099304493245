const slugify = text => {
    const trMap = {
        'çÇ':'c',
        'ğĞ':'g',
        'şŞ':'s',
        'üÜ':'u',
        'ıİ':'i',
        'öÖ':'o',
    };
    for(let key in trMap) {
        text = text.replace(new RegExp('['+key+']','g'), trMap[key]);
    }
    return  text.replace(/[^-a-zA-Z0-9\s]+/ig, '')
        .replace(/\s/gi, "-")
        .replace(/[-]+/gi, "-")
        .toLowerCase();

};

const TOC = {
    contents: [],
    errorMessages: {
        TABLE_OF_CONTENT_ELEMENT_ID_NOT_DEFINED_ON_CONTENT: 'Table of Content element id not defined on content!',
        TABLE_OF_CONTENT_ELEMENT_NOT_FOUND: 'Table of Content element `#:id` not found!',
    },
    allowReports: false,

    createTableOfContent(content) {
        const tableOfContentId = content.dataset.tableOfContent;

        const tableOfContentEl = this.getTableOfContentEl(tableOfContentId);

        if (!tableOfContentEl) {
            return;
        }

        const headingEls = [...content.children].filter((e) => e.tagName.startsWith('H'));

        const serialized = this.getSerializedArrayOfContent(headingEls);

        this.render(tableOfContentEl, serialized);

        this.handleContentScroll(headingEls, tableOfContentEl)
    },

    getSerializedArrayOfContent(headingEls) {
        let headings = [];

        let headingCounter = 1;

        headingEls.forEach((e) => {
            const level = parseInt(e.tagName.substring(1)),
                text = e.innerText,
                id = `${slugify(text)}-${headingCounter}`;

            e.id = id;

            headings.push({
                el: e,
                id,
                text,
                level,
                segments: [],
            });

            headingCounter++;
        });

        const serialize = (items) => {
            const arr = [];
            const minLevel = Math.min(...items.map(item => item.level));

            items.forEach(item => {
                if (item.level === minLevel) {
                    arr.push(item);
                } else {
                    arr[arr.length - 1].segments.push(item);
                }
            });

            return arr;
        }

        const mapSerialize = (item) => {
            item.segments = serialize(item.segments);

            item.segments = item.segments.map(item => mapSerialize(item));

            return item;
        }

        return serialize(headings).map(item => mapSerialize(item));
    },

    render(el, serialized) {
        const renderSerializedItem = (parent, segments) => {
            const ul = document.createElement('ul');

            segments.forEach(item => {
                const li = document.createElement('li');

                li.innerText = item.text;

                li.dataset.follow = item.id;

                li.addEventListener('click', (e) => {
                    e.stopPropagation();

                    this.jump(el, item.el);
                })

                renderSerializedItem(li, item.segments);

                ul.appendChild(li);
            });

            parent.appendChild(ul);
        }

        renderSerializedItem(el, serialized);
    },

    getTableOfContentEl(tableOfContentId) {
        if (!tableOfContentId) {
            this.report(this.errorMessages.TABLE_OF_CONTENT_ELEMENT_ID_NOT_DEFINED_ON_CONTENT);

            return null;
        }

        const tableOfContentEl = document.getElementById(tableOfContentId);

        if (!tableOfContentEl) {
            this.report(this.errorMessages.TABLE_OF_CONTENT_ELEMENT_NOT_FOUND.replaceAll(':id', tableOfContentId));

            return null;
        }

        return tableOfContentEl;
    },

    handleContentScroll(headingEls, tableOfContentEl) {
        window.addEventListener('scroll', (event) => {
            let active = null;

            headingEls.forEach(el => {
                if (el.getBoundingClientRect().top + window.scrollY < window.scrollY) {
                    active = el;
                }
            });

            if(active) {
                this.setListItemToActive(tableOfContentEl, active.id);
            }
        });
    },

    jump(tableOfContentEl, el) {
        scrollTo({
            top: el.getBoundingClientRect().top + window.scrollY + 1,
            behavior: "smooth"
        });
    },

    setListItemToActive(tableOfContentEl, id) {
        const activeListItem = tableOfContentEl.querySelector(`[data-follow="${id}"]`);

        const others = tableOfContentEl.querySelectorAll(`li:not([data-follow="${id}"]).active`);

        others.forEach(e => {
            e.classList.remove('active');
        });

        if (activeListItem) {
            activeListItem.classList.add('active');
        }
    },

    init(contentSelector = '.content') {
        this.contents = document.querySelectorAll(contentSelector);

        this.contents.forEach((content) => {
            this.createTableOfContent(content);
        });
    },

    report(message, type = 'error') {
        if (!this.allowReports) return;

        console[type](message);
    },

    disableReports() {
        this.allowReports = false;
    },
}

export default TOC;