document.addEventListener('DOMContentLoaded', () => {
  const element = document.querySelectorAll('#header .dropdown');

  window.addEventListener('scroll', function() {
    element.forEach(el => {
      el.classList.remove('dropdown-open');
    });
  });
});

document.addEventListener('DOMContentLoaded', () => {
  const menuButtons = document.querySelectorAll('.dropdown .dropdown-toggle');
  const overlay = document.querySelector('.dark-overlay');

  menuButtons.forEach((menuButton) => {
    menuButton.addEventListener('click', toggleMenu);
  });

  function toggleMenu() {
    const isOpen = this.classList.contains('dropdown-open');
    if (isOpen) {
      overlay.style.opacity = '0';
      overlay.style.visibility = 'hidden';
      this.classList.remove('dropdown-open');
    } else {
      overlay.style.opacity = '100';
      overlay.style.visibility = 'visible';
      menuButtons.forEach(btn => btn.classList.remove('dropdown-open')); // Sadece bir menü açık olabilir
      this.classList.add('dropdown-open');
    }
  }

  window.addEventListener('scroll', () => {
    overlay.style.opacity = '0';
    overlay.style.visibility = 'hidden';
    menuButtons.forEach(btn => btn.classList.remove('dropdown-open'));
  });
});
