import './copy-clipboard.js';
import './header.js';
import './lazyload.js';
import './mega-menu.js';
import './mobile-nav.js';
import './page-title-change.js';
import './scroll-up.js';
import './swiper.js';
import './switch-theme.js';

document.addEventListener('DOMContentLoaded', () => {
    const itemToggles = document.querySelectorAll('.toggle');

    itemToggles.forEach(el => {
        el.addEventListener('click', () => {
            el.parentElement.classList.toggle('open');
        })
    })
});
