import CustomCursor from "custom-cursor.js";

// Following options represent the defaults
const options = {
    hideTrueCursor: false,
    focusElements: [
        {
            elements: 'input, textarea',
            focusClass: 'cursor--form',
        },
        {
            elements: 'a, button, .btn, .toggle, .modal-close, .body-text-link, .accordion-head',
            focusClass: 'cursor--special-focused',
        },
    ],
}

const element = '.cursor'
new CustomCursor(element, options).initialize()