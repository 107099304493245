import IMask from 'imask';

document.addEventListener('DOMContentLoaded', () => {
    const numbers = document.querySelectorAll('.form-number');

    numbers.forEach(el => {
        const mask = IMask(el, {
            mask: Number
        });
    })
});