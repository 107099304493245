document.addEventListener('DOMContentLoaded', () => {
    const title = document.title;
    const outTitle = "Think Big, Get Big";

    window.onblur = function () {
        document.title = outTitle;
    }

    window.onfocus = function () {
        document.title = title;
    }
});