document.addEventListener('DOMContentLoaded', () => {
    const dropdowns = document.querySelectorAll('.modal');
    dropdowns.forEach(dropdown => {
        dropdown.setAttribute('data-lenis-prevent', '');
    });
});

import Lenis from 'lenis'

const lenis = new Lenis({
    // wrapper: (ellement) => !element.hasAttribute('data-lenis-prevent')
})

function raf(time) {
    lenis.raf(time)
    requestAnimationFrame(raf)
}

requestAnimationFrame(raf)
