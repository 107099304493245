document.addEventListener('DOMContentLoaded', () => {
    const cookieEls = document.querySelectorAll('.cookie');

    cookieEls.forEach(cookieEl => {
        const cookieButtons = cookieEl.querySelectorAll('.btn');

        cookieButtons.forEach(button => {
            button.addEventListener('click', () => {
                document.cookie = "cookieAccepted=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/";

                cookieEl.style.display = 'none';
            });
        });

        if (document.cookie.split(';').some((item) => item.trim().startsWith('cookieAccepted='))) {
            cookieEl.style.display = 'none';
        }
    });
});