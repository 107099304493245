document.addEventListener('DOMContentLoaded', () => {
  const counterContainers = document.querySelectorAll('.counter-container');
  if (!counterContainers.length) return;

  const counterTotalMs = 3000; // Sayaç animasyonu için toplam süre

  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const startCounting = (container) => {
    const countEl = container.querySelector('.count');
    countEl.dataset.count = parseInt(countEl.dataset.count, 10);
    countEl.innerHTML = '0';
    count(countEl);
  };

  const count = (el) => {
    let current = parseInt(el.innerHTML, 10);
    const target = parseInt(el.dataset.count);
    const increment = target / (counterTotalMs / 100);

    const updateCounter = () => {
      current += increment;
      if (current >= target) {
        el.innerHTML = formatNumber(target);
      } else {
        el.innerHTML = formatNumber(Math.ceil(current));
        setTimeout(updateCounter, 100);
      }
    };

    updateCounter();
  };

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        startCounting(entry.target);
        observer.unobserve(entry.target);
      }
    });
  }, { threshold: 1.0 });

  counterContainers.forEach(container => {
    if (isInViewport(container)) {
      startCounting(container);
    } else {
      observer.observe(container);
    }
  });
});
