const ThemeSwitcher = {
    isDark() {
        return localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches);
    },

    theme() {
        return this.isDark() ? 'dark' : 'light';
    },

    setTheme(theme) {
        localStorage.setItem('theme', theme);

        this.themeChanged();
    },

    themeChanged() {
        this.changeThemeImages();

        this.changeDocumentElementClass();

    },

    changeThemeImages() {
        const themeChangeImages = document.querySelectorAll('.theme-change-img');

        themeChangeImages.forEach(el => {
            const dataset = `${this.theme()}img`;

            el.src = el.dataset[dataset] ?? el.src;
        });
    },

    changeDocumentElementClass() {
        if (this.isDark()) {
            return document.documentElement.classList.add('dark');
        }

        document.documentElement.classList.remove('dark');
    },

    addSwitchEventListener() {
        const switchThemeButtons = document.querySelectorAll('.switch-theme');

        switchThemeButtons.forEach(el => {
            el.addEventListener('click', () => {
                if (this.isDark()) {
                    return this.setTheme('light');
                }

                this.setTheme('dark');
            });
        });
    },

    init() {
        const originalSetItem = localStorage.setItem;

        localStorage.setItem = function(key, value) {
            const event = new Event('localStorageChanged');

            event.value = value; // Optional..
            event.key = key; // Optional..

            document.dispatchEvent(event);

            originalSetItem.apply(this, arguments);
        };

        this.addSwitchEventListener();

        this.themeChanged();
    }
}

ThemeSwitcher.init();