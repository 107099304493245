document.addEventListener('DOMContentLoaded', () => {
  const copies = document.querySelectorAll('.copy');

  copies.forEach(el => {
    el.addEventListener('click', () => {
      const text = el.parentElement.parentElement.querySelector('.copy-area').innerText;

      navigator.clipboard.writeText(text);

      // el.classList.add('copied');
      //
      // setTimeout(() => {
      //     el.classList.remove('copied');
      // }, 600);
    });
  })
})
