const open = (el) => {
    const height = el.scrollHeight;

    el.style.height = `${height}px`;

    setTimeout(() => {
        el.style.height = 'max-content';
    }, 300);
}

const close = (el) => {
    if (!el) {
        return;
    }

    const height = el.scrollHeight;

    el.style.height = `${height}px`;

    setTimeout(() => {
        el.style.height = `0`;
    }, 1);
}

const closeSameLayer = (parent) => {
    const activeItems = parent.parentElement.querySelectorAll('.collapse-item.open');

    activeItems.forEach(item => {
        if (item !== parent) {
            item.classList.remove('open');

            const body = item.querySelector('.collapse-body');

            close(body);
        }
    });
}

document.addEventListener('DOMContentLoaded', () => {
    const collapseHeads = document.querySelectorAll('.collapsible .collapse-head');

    collapseHeads.forEach(head => {
        const parent = head.parentElement;

        const body = parent.querySelector('.collapse-body');

        if (!body) {
            return;
        }

        head.addEventListener('click', () => {

            closeSameLayer(parent);

            parent.classList.toggle('open');

            if (parent.classList.contains('open')) {
                open(body);
            } else {
                close(body);
            }
        })
    });
});