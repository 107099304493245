import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

document.addEventListener('DOMContentLoaded', () => {
    const benefits = document.querySelector('#homepage-benefits .content .benefits');


    if (!benefits) {
        return;
    }

    const items = gsap.utils.toArray(".benefits .benefit-item");

    const images = gsap.utils.toArray("#homepage-benefits .content figure");

    const progressCursor = benefits.querySelector('.progress-cursor');

    const progressContainer = benefits.querySelector('.progress-container');

    progressContainer.style.height = items[0].getBoundingClientRect().height + 'px';

    const scrollTrigger = {
        trigger: '#homepage-benefits .content',
        start: 'center center',
        end: 'bottom+=800 top',
        scrub: true,
        pin: true,
        pinSpacing: true
    };

    const tl = gsap.timeline({
        scrollTrigger,
        yoyo: true,
    });

    const styles = window.getComputedStyle(benefits);

    let cssText = styles.cssText;

    if (!cssText) {
        cssText = Array.from(styles).reduce((str, property) => {
            return `${str}${property}:${styles.getPropertyValue(property)};`;
        }, '');
    }

    const gap = parseInt(cssText.match(/column-gap:(.*?)px;/)[1]);

    const heights = items.map(item => item.getBoundingClientRect().height);

    let totalHeights = 0;

    heights.forEach((height, index) => {
        totalHeights += height;

        if (index !== 0) {
            totalHeights += gap;
        }
    });

    items.forEach((item, index) => {
        if (index !== 0) {
            tl.addLabel("positionY", ">");
            const center = getCenterOfContainer(totalHeights, heights, index, index, gap);

            tl.fromTo(item,
                {
                    translateY: innerHeight,
                }, {
                    translateY: center,
                    duration: 1000,
                }, 'positionY');

            tl.fromTo(images[index],
                {
                    translateY: innerHeight,
                }, {
                    translateY: 0,
                    duration: 1000,
                }, 'positionY');

            tl.fromTo(images[index - 1],
                {
                    translateY: 0,
                }, {
                    translateY: -innerHeight,
                    duration: 1000,
                }, 'positionY');

            tl.to(item, {
                opacity: 1,
            });

            let top = 0;

            for (let i = 0; i < index; i++) {
                top += items[i].getBoundingClientRect().height + gap;
                const center = getCenterOfContainer(totalHeights, heights, i, index, gap);

                tl.to(items[i], {
                    translateY: center,
                    duration: 1000
                }, 'positionY');
            }

            tl.to(progressCursor, {
                translateY: getCenterOfContainer(totalHeights, heights, 0, index, gap),
                height: item.getBoundingClientRect().height,
                top: top,
                duration: 1000
            }, 'positionY');

            tl.to(progressContainer, {
                height: progressContainer.getBoundingClientRect().height + top,
                translateY: getCenterOfContainer(totalHeights, heights, 0, index, gap),
                duration: 500
            }, 'positionY');

            tl.to(items[index - 1], {
                duration: 2000,
                opacity: .24,
            }, 'positionY');

        } else {
            tl.to(item, {
                opacity: 1
            });

            tl.to(progressCursor, {
                height: item.getBoundingClientRect().height,
                duration: 1000
            });
        }
    });

    const center = getCenterOfContainer(totalHeights, heights, 0, 0, gap);

    items[0].style.transform = "translateY(" + center + "px)";
    progressContainer.style.transform = "translateY(" + center + "px)";
    progressCursor.style.transform = "translateY(" + center + "px)";
});

const getCenterOfContainer = (total, allItems, activeItemIndex, currentIndex, gap) => {
    if (currentIndex === allItems.length - 1) {
        return 0;
    }

    let itemsTotal = 0;

    for (let i = 0; i <= currentIndex; i++) {
        itemsTotal += allItems[i];
    }

    if (activeItemIndex > -1) {
        itemsTotal += gap;
    }

    total = (total / 2) - (itemsTotal / 2);

    return total;
}
