document.querySelectorAll('.form-group input, .form-group textarea').forEach((element) => {
    element.addEventListener('focus', () => {
        const parent = element.closest('.form-group');
        if (parent) {
            parent.classList.add('field-focused');
        }
    });

    element.addEventListener('blur', () => {
        const parent = element.closest('.form-group');
        if (parent) {
            if (element.value.trim() === '') {
                parent.classList.remove('field-focused');
            }
        }
    });
});
